import { OutputFormat } from '../organizations/form-setting';

export interface IAsset {
  id: string;
  key: string;
  // the order of the assets versions
  index: number;
  // the EVA index
  selectsIndex: number;
  createdAt: string;
  versionNumber: number;
  shootAssetVersionNumber: number;
  postAssetVersionNumber: number;
  thumbnailUri: string;
  reviewUri: string;
  ContentExtracting: boolean;
  shootAssetReviewUri: string;
  uri?: string;
  type: string;
  shotDate: string;
  shotByUserName: string;
  rawUri?: string;
  productTouchId?: string;
  productTouchAssetId?: string;
  masterSuiteTouchId?: string;
  masterSuiteTouchAssetId?: string;
  shootAssetId?: string;
  postAssetId?: string;
  outputFormatId?: string;
  manuallyUploaded?: boolean;
  productId?: string;
  batchId?: string;
  internalReviewById?: string;
  internalReviewByName?: string;
  internalReviewDate?: string;
  internalReviewState?: EStateAsset;
  masterSuiteTouchIndex: number;
  packageAssetIndex: number;
  videoIndex?: number;

  clientReviewById?: string;
  clientReviewByName?: string;
  clientReviewDate?: string;
  clientReviewState?: EStateAsset;
  label?: string;
  subLabel?: string;
  status?: string;
  assetState?: EStateAsset;
  copyAssets: {
    key: string;
    packageAssetIndex: number;
    shootPlaceholderThumbnailUri: string;
    shootPlaceholderUri: string;
  }[];
  childIndex: number;
  additionalAssetIndex?: number;
  isEnhanced?: boolean;
  enhancementFlag?: boolean;
  enhancementIndex?: number;
  postProcessedDate?: string;
  postByUserName: string;
  selected: boolean;
  released: boolean;
}

export interface IMasterSuiteProjection {
  masterSuiteName: string;
  masterSuiteId: string;
  products: IProductProjection[];
}

export interface IProductProjection {
  id: string; //product id
  barcode: string;
  reference: string;
  masterSuiteId?: string;
  masterSuiteName?: string;
  enhancementVariantId?: string;
  enhancementVariantName?: string;
  touches: IAssetDetailResponse[];
}

export interface IAssetDetailResponse {
  id: string;
  productId: string; // touchId
  barcode?: string;
  reference?: string;
  masterSuiteId?: string;
  masterSuiteName?: string;
  masterSuiteTouchId?: string;
  masterSuiteTouchName?: string;
  enhancementVariantId?: string;
  enhancementVariantName?: string;
  postAssets?: IAsset[];
  shootAssets?: IAsset[];
  outputAssets?: IAsset[];
}

export enum EStateAsset {
  IN_REVIEW = 'InReview',
  APPROVED = 'Accepted',
  REJECTED = 'Rejected',
  COMPLETE = 'Complete',
  PROCESSING = 'Processing',
  OUT_DATED = 'OutDated',
  RELEASED = 'Released',
}

export enum EAssetType {
  SHOOT_ASSETS = 'shoot-asset',
  POST_ASSETS = 'post-asset',
  OUTPUT_ASSETS = 'output-asset',
}

export interface latestOutputAssets extends OutputFormat {
  outputAssets: IAsset[];
}
